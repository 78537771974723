export const appName = {
  el: 'Ταρπινίδης και Συνεργάτες',
  en: 'Tarpinidis and Associates',
};

const appSlug = 'tarpinidis';
const baseUrl = 'https://cms.soundwire.dev';

const settings = {
  defaultLocale: 'el',
  supportedLocales: ['el', 'en'],

  baseUrl,
  contentApi: `${baseUrl}/api/content/${appSlug}`,
  assetsApi: `${baseUrl}/api/assets/${appSlug}`,
  imgixUrl: `https://soundwire.imgix.net/${appSlug}`,
  assetsMetaApi: `${baseUrl}/api/apps/${appSlug}/assets`,
  graphqlPath: `/api/content/${appSlug}/graphql`,

  squidexClient: {
    id: 'tarpinidis:default',
    secret: 'bzhfffvtrxye598eyxqkbxzep9ltygqxmax4t9fpxqmx',
  },

  googleMapsApiKey: 'AIzaSyBXb42FiZi_WoZqR-CgOEDirkzGsdHnMM0',
};

export default settings;
